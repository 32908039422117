import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AccountManager from '../../managers/Account';

function LoginWidget({ isLoggedIn, onLoginButton, onLogoutButton }) {
  // State variables using useState
  const [openNav, setNavstate] = useState(false);

  const toggleNav = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (openNav) {
      setNavstate(false);
    } else {
      setNavstate(true);
    }
  };

  const logOut = (e) => {
    if (e) {
      e.preventDefault();
    }
    onLogoutButton();
  };

  return (
    <div className="login-widget">
      <div className="login-widget__icons">
        <a
          href="#"
          className={openNav ? 'login-widget__hamburger open' : 'login-widget__hamburger'}
          onClick={(e) => toggleNav(e)}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </a>
        <a
          href="#"
          className="login-widget__profile"
          onClick={(e) => toggleNav(e)}>
          <img src="https://assets.heyethos.com/ethos/v2/ui/icon-profile.svg" alt="profile pic" />
        </a>
      </div>
      <div className={openNav ? 'dropdown-widget__menu open' : 'dropdown-widget__menu'}>
        {isLoggedIn
          ? (
            <>
              <div className="dropdown-widget__menu--item">
                <Link
                  to="/account/collection"
                  onClick={() => toggleNav()}>
                  My Membership
                </Link>
              </div>
              <div className="dropdown-widget__menu--item">
                <Link
                  to="/account/details"
                  onClick={() => toggleNav()}>
                  Contact Information
                </Link>
              </div>
              {AccountManager.isVerified()
              && (
              <div className="dropdown-widget__menu--item">
                <Link
                  to="/account/password"
                  onClick={() => toggleNav()}>
                  Account Password
                </Link>
              </div>
              )}
              {AccountManager.isVerified()
              && (
              <div className="dropdown-widget__menu--item">
                <Link
                  to="/account/billing"
                  onClick={() => toggleNav()}>
                  Billing Details
                </Link>
              </div>
              )}
              <div className="dropdown-widget__menu--item">
                <a
                  href="#"
                  onClick={(e) => logOut(e)}>
                  Logout
                </a>
              </div>
            </>
          )
          : (
            <>
              <div className="dropdown-widget__menu--item">
                <Link
                  to="/login"
                  onClick={() => toggleNav()}>
                  Log In
                </Link>
              </div>
              <div className="dropdown-widget__menu--item">
                <Link
                  to="/register"
                  onClick={() => toggleNav()}>
                  Register
                </Link>
              </div>
            </>
          )}
        
      </div>
    </div>
  );
}

export default LoginWidget;
