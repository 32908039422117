import React, { useState, useEffect } from 'react';

const LoyaltyFlexContent = ({ lang }) => {
  // const [transferCode, setTransferCode] = useState(null);

  const scrollToTop = (e) => {
    e.preventDefault();
    // const scrollToElement = document.getElementById(element);
    // scrollToElement.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="flex-content -no-margin">
      <div className="flex-content--copy">
        {process.env.SITE_NAME === 'Northie'
          ? (
            <div className="flex-content__inner">
              <div className="flex-content--title">This week only</div>
              <div className="flex-content--description">
                {'Joining the Northie Mountain Loyalty Program this week gives you access to our latest documentary "Northie in Norway". Join now to view it before it\'s removed!'}
              </div>
            </div>
          )
          : (
            <div
              className="flex-content__inner"
              dangerouslySetInnerHTML={{ __html: process.env.CUSTOM_BENEFITS_DESCRIPTION }}>
            </div>
          )}
      </div>
      <div
        className="flex-content--image"
        style={{
          backgroundImage: 'url(' + process.env.ALTERNATE_BACKGROUND + ')',
        }}>
      </div>
    </div>
  );
};
export default LoyaltyFlexContent;
