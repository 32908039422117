/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const LoyaltyBenefits = ({ lang }) => {
  const copyStrings = {
    en: {
      heading: 'What do I get with the Founding 512?',
      benefit1: 'Contests to win VIP experiences',
      benefit2: 'Birthday vouchers',
      benefit3: 'Exclusive content',
    },
  };

  const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div
      className="storefront-benefits"
      style={{
        backgroundImage: 'url(' + process.env.HERO_BACKGROUND + ')',
      }}>
      <div className="storefront-benefits__overlay"></div>
      <div className="storefront-benefits__inner">
        <div className="storefront-benefits__title">{copyStrings[lang].heading}</div>
        <div className="custom-benefits__row">
          <Slider {...settings}>
            <div>
              <div className="storefront-benefits__item">
                <div className="storefront-benefits__item--title">{copyStrings[lang].benefit1}</div>
                <div
                    className="storefront-benefits__item--image"
                    style={{
                      backgroundImage: 'url(https://assets.heyethos.com/austindraft/utility/thumb-utility2.jpg)',
                    }}>
                </div>
              </div>
            </div>
            <div>
              <div className="storefront-benefits__item">
                <div className="storefront-benefits__item--title">{copyStrings[lang].benefit2}</div>
                <div
                    className="storefront-benefits__item--image"
                    style={{
                      backgroundImage: 'url(https://assets.heyethos.com/austindraft/utility/thumb-utility5.jpg)',
                    }}>
                </div>
              </div>
            </div>
            <div>
              <div className="storefront-benefits__item">
                <div className="storefront-benefits__item--title">{copyStrings[lang].benefit3}</div>
                <div
                    className="storefront-benefits__item--image"
                    style={{
                      backgroundImage: 'url(https://assets.heyethos.com/austindraft/utility/thumb-utility4.jpg)',
                    }}>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};
export default LoyaltyBenefits;
