import React, { Component } from 'react';
import LoyaltyLanding from '../components/loyalty/loyaltyLanding';
import EDropLanding from '../components/edrop/edropLanding';
import HowItWorks from '../components/edrop/bonLookHowItWorks';
import LoyaltyBenefits from '../components/loyalty/loyaltyBenefits';
import LoyaltyFlexContent from '../components/loyalty/loyaltyFlexContent';
import ClassicSixTiers from '../components/loyalty/classicSixTiers';
import AssetFAQ from '../components/collection/assetFAQ';
import UtilityManager from '../managers/Utility';
import SimpleModal from '../components/modal/modal';
import Throbber from '../components/throbber';
import { getParameterByName } from '../managers/Helpers';

class Setup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorData: {},
      transferToken: null,
      transferCookie: null,
      asset: null,
      showModal: true,
      benefits: [],
      language: 'en',
    };
  }

  setTransferToken = (token) => {
    Object.entries(sessionStorage).map((obj) => {
      const key = obj[0];
      if (key.includes('NFT_TRANSFER')) {
        sessionStorage.removeItem(key);
      }
      return true;
    });
    // force the one in the URL to be in memory
    this.setState({
      transferCookie: 'NFT_TRANSFER_' + token,
      transferToken: token,
    });
    // sessionStorage.setItem('NFT_TRANSFER_' + token, token);
  }

  componentDidMount() {
    // TEMPORARY FIX FOR LOCAL STORAGE BUG
    localStorage.removeItem('NFT_TOKEN_TRANSFERRED_ddca2275-dce2-4a71-bf19-85d9eb3ba43b');
    localStorage.removeItem('NFT_TOKEN_TRANSFERRED_84c3ceeb-6b75-4c77-9ada-51344a1f1f5c');
    localStorage.removeItem('NFT_TOKEN_TRANSFERRED_7a2fd490-7c3c-466b-9aad-0524bd0b957e');

    document.title = process.env.CREATOR_SUBDOMAIN === 'account' ? 'ethos' : process.env.SITE_NAME + ' | Join the ' + process.env.DIGITAL_ASSET_TYPE;
    if (getParameterByName('id', window.location.href)) {
      this.setTransferToken(getParameterByName('id', window.location.href));
    } else if (process.env.EDROP_CLAIM_TOKEN) {
      this.setTransferToken(process.env.EDROP_CLAIM_TOKEN);
    } else {
      window.location.href = '/';
    }
  }

  loadTransferDetails = async (transferDetails) => {
    console.log('=== WE HAVE TRASNFER DETAILS ===');
    console.log(transferDetails);
    if (transferDetails.asset) {
      this.setState({
        asset: transferDetails.asset,
        showModal: false,
      });
    }
    // get any preview benefits
    const previewBenefits = await UtilityManager.getPreviewUtility(transferDetails.asset.assetId);
    if (previewBenefits && previewBenefits.length > 0) {
      this.setState({
        benefits: previewBenefits,
      });
    }
  }

  componentWillUnmount() {
    document.title = process.env.SITE_NAME;
  }

  render() {
    const {
      language, showModal, transferCookie, transferToken,
    } = this.state;
    return (
      <div>
        <SimpleModal isOpen={showModal}>
          <Throbber throbberText="Loading membership..." />
        </SimpleModal>
        {/* <LoyaltyLanding lang={language} onTransferDetailsLoaded={(transferDetails) => this.loadTransferDetails(transferDetails)} transferCookie={transferCookie} transferToken={transferToken} /> */}
        
        <EDropLanding onTransferDetailsLoaded={(transferDetails) => this.loadTransferDetails(transferDetails)} transferCookie={transferCookie} transferToken={transferToken} />

        <HowItWorks lang={language} />

        <LoyaltyBenefits lang={language} />

        <LoyaltyFlexContent lang={language} />

        {/* <ClassicSixTiers lang={language} /> */}

        <div className="storefront-faq">
          <div className="storefront-faq--title">FAQ</div>
          <AssetFAQ noHeader lang={language} />
        </div>
      </div>
    );
  }
}

export default Setup;
